import React from 'react'
import { Wrapper, Section, BottomSection, ContentHeader } from './helpers'
import PageBgColor from '../../pages/PageBgColor'

type ConfirmationModalContentProps = {
  title: string
  onDismiss: () => void
  topContent: () => React.ReactNode
  bottomContent: () => React.ReactNode
}

const ConfirmationModalContent = ({ title, bottomContent, onDismiss, topContent }: ConfirmationModalContentProps) => {
  return (
    <Wrapper>
      <PageBgColor>
        <Section>
          <ContentHeader onDismiss={onDismiss}>{title}</ContentHeader>
          {topContent()}
        </Section>
        <BottomSection gap="12px">{bottomContent()}</BottomSection>
      </PageBgColor>
    </Wrapper>
  )
}

export default ConfirmationModalContent
