import { ChainId } from '@pancakeswap-libs/sdk'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { Button, LinkExternal } from '@pancakeswap-libs/uikit'
import { ArrowUpCircle } from 'react-feather'
import { AutoColumn } from '../Column'
import { getEtherscanLink } from '../../utils'
import { Wrapper, Section, ContentHeader, LoaderIcons } from './helpers'
import PageBgColor from '../../pages/PageBgColor'

type TransactionSubmittedContentProps = {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
}

const TransactionSubmittedContent = ({ onDismiss, chainId, hash }: TransactionSubmittedContentProps) => {
  const theme = useContext(ThemeContext)

  return (
    <Wrapper>
      <PageBgColor>
        <Section>
          <ContentHeader onDismiss={onDismiss}>Transaction submitted</ContentHeader>
          <LoaderIcons>
            <ArrowUpCircle strokeWidth={0.5} size={97} color={theme.colors.primary} />
          </LoaderIcons>
          <AutoColumn gap="8px" justify="center">
            {chainId && hash && (
              <LinkExternal href={getEtherscanLink(chainId, hash, 'transaction')}>View on FIERO Explorer</LinkExternal>
            )}
            <Button onClick={onDismiss} mt="20px">
              Close
            </Button>
          </AutoColumn>
        </Section>
      </PageBgColor>
    </Wrapper>
  )
}

export default TransactionSubmittedContent
