import { MenuEntry } from '@pancakeswap-libs/uikit'
import { BsTwitter, BsFacebook, BsYoutube, BsInstagram, BsTelegram } from 'react-icons/bs'
import { LaunchImg, MLMImg, StakeImg, TokenImg, TradeImg, fieroHomeImg, fieroStakeImg } from '../PageHeader'

export const NextProjectUrl = 'https://decentralized-staking.mobiloitte.io/#/'
const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: fieroHomeImg,
    href: `${NextProjectUrl}app/dashboard`,
  },
  {
    label: 'Trade',
    icon: TradeImg,
    items: [
      {
        label: 'Exchange',
        href: '/swap',
      },
      {
        label: 'Liquidity',
        href: '/pool',
      },
    ],
  },
  {
    label: 'IDOs Launch',
    icon: LaunchImg,
    items: [
      {
        label: 'Create IDO',
        href: `${NextProjectUrl}app/create-launched`,
      },
      {
        label: 'View IDOs Pool',
        href: `${NextProjectUrl}app/launched-live-presale`,
      },
    ],
  },
  // {
  //   label: 'Premium Launch',
  //   icon: PremiumImg,
  //   items: [
  //     {
  //       label: 'Create IDO',
  //       href: `${NextProjectUrl}app/public-create-launched`,
  //     },
  //     {
  //       label: 'View IDOs Pool',
  //       href: `${NextProjectUrl}app/public-create-launched`,
  //     },
  //   ],
  // },
  {
    label: 'Token Locker',
    icon: TokenImg,
    items: [
      {
        label: 'Lock LP Tokens',
        href: `${NextProjectUrl}app/lock-liquidity`,
      },
      {
        label: 'Lock Token',
        href: `${NextProjectUrl}app/lock-liquidity`,
      },
    ],
  },
  {
    label: 'Staking',
    icon: StakeImg,
    items: [
      {
        label: 'Create Staking Pool',
        href: `${NextProjectUrl}app/create-stake`,
      },
      {
        label: 'View Staking Pool',
        href: `${NextProjectUrl}app/create-stake`,
      },
    ],
  },
  {
    label: 'Fiero Staking',
    icon: fieroStakeImg,
    href: `${NextProjectUrl}app/fiero-stake`,
  },
  {
    label: 'Token Creator',
    icon: TokenImg,
    href: `${NextProjectUrl}app/token`,
  },
  {
    label: 'MLM',
    icon: MLMImg,
    href: `${NextProjectUrl}app/affiliate`,
  },
]

export default config

export const socialsLinks: any = [
  {
    label: 'Telegram',
    icon: BsTelegram,
    href: '#',
  },

  {
    label: 'Twitter',
    icon: BsTwitter,
    href: 'https://twitter.com/',
  },
  {
    label: 'Facebook',
    icon: BsFacebook,
    href: 'https://www.facebook.com',
  },
  {
    label: 'Instagram',
    icon: BsInstagram,
    href: 'https://www.instagram.com/fieresofficial/',
  },
  {
    label: 'Youtube',
    icon: BsYoutube,
    href: 'https://www.youtube.com/',
  },
]
