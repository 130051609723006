import React, { useCallback, useEffect, useState } from 'react'
import { ThemeProvider as SCThemeProvider } from 'styled-components'
import { light, dark } from '@pancakeswap-libs/uikit'

const CACHE_KEY = 'IS_DARK'

export interface ThemeContextType {
  isDark: boolean
  toggleTheme: () => void
}

const ThemeContext = React.createContext<ThemeContextType>({ isDark: true, toggleTheme: () => null })

const ThemeContextProvider: React.FC = ({ children }) => {
  const [isDark, setIsDark] = useState(() => {
    const isDarkUserSetting = localStorage.getItem(CACHE_KEY)
    return isDarkUserSetting ? JSON.parse(isDarkUserSetting) : false
  })

  const handleSetup = useCallback((event) => {
    if (event && event.data && typeof event.data === 'string' && event.data.startsWith('[iFrameSizer]message:')) {
      const dataStr = event.data.substring('[iFrameSizer]message:'.length)
      const data = JSON.parse(dataStr)
      // console.log('data.isDark', data.isDark)
      setIsDark(() => data.isDark)
    }
  }, [])
  useEffect(() => {
    window.addEventListener('message', handleSetup)
    return () => {
      window.removeEventListener('message', handleSetup)
    }
  }, [handleSetup])

  const toggleTheme = () => {
    setIsDark((prevState: any) => {
      localStorage.setItem(CACHE_KEY, JSON.stringify(!prevState))
      return !prevState
    })
  }
  const lightTheme = {
    ...light,
    button: {
      ...light.button,
      primary: {
        ...light.button.primary,
        background: 'linear-gradient(92.79deg, #FFB000 -32.2%, #FF564D 5.51%, #FF0098 54.01%, #5D00C1 110.93%)',
        backgroundActive: '#3c226a',
        backgroundHover: '#3c226a',
      },

      text: {
        ...light.button.text,
        color: '#FFFAFA',
      },
      tertiary: {
        ...light.button.tertiary,
        color: '#ffffff',
        background: '#23161B',
        backgroundActive: '#ff0098',
        backgroundHover: '#FF564D',
      },
      secondary: {
        ...light.button.secondary,
        color: '#000',
        background: '#3c226a',
        backgroundActive: '#3c226a',
        backgroundHover: '#3c226a',
        border: '2px solid #3c226a',
        borderColorHover: '#3c226a',
      },
      subtle: {
        ...light.button.subtle,
        background: 'linear-gradient(92.79deg, #FFB000 -32.2%, #FF564D 5.51%, #FF0098 54.01%, #5D00C1 110.93%)',
        backgroundActive: '#3c226a',
        backgroundHover: '#3c226a',
      },
    },
    colors: {
      ...light.colors,
      primary: '#fff',
      primaryBright: '#3c226a',
      primaryDark: '#3c226a',
      textSubtle: '#ffffff',
      secondary: '#3c226a',
      text: '#FFFFFF',
      input: '#201117',
      tertiary: '#140F11',
      background: '#000000',
    },

    nav: {
      background: '#0C090A',
      hover: '#012b2c',
      '&:hover': {
        borderRight: '3px solid #ff0098',
      },
    },
    card: {
      ...light.card,
      background: '#150a0f !important',
      backdropFilter: 'blur(3.22162px) !important',
      // borderRadius: '15px',
      boxShadow: '0px 0px 20px rgba(255, 0, 152, 0.4)',
    },
  }
  const darkTheme = {
    ...dark,
    alert: { background: '#272c2c' },
    card: {
      ...dark.card,
      background: '#272c2c',
    },
    modal: { background: '#272c2c' },
    nav: { background: '#272c2c', hover: '#012b2c' },
    radio: { handleBackground: '#272c2c' },
    toggle: { handleBackground: '#272c2c' },
    button: {
      ...dark.button,
      primary: {
        ...dark.button.primary,
        background: '#2fb4f1',
        backgroundActive: '#1782b3',
        backgroundHover: '#1782b3',
      },
      text: {
        ...dark.button.text,
        color: '#2fb4f1',
        backgroundHover: '#1782b3',
      },
      tertiary: {
        ...dark.button.tertiary,
        color: '#ffffff',
        background: '#1782b3',
        backgroundActive: '#1782b3',
        backgroundHover: '#1782b3',
      },
      secondary: {
        ...dark.button.secondary,
        color: '#2fb4f1',
        border: '2px solid #2fb4f1',
        borderColorHover: '#2fb4f1',
      },
      subtle: {
        ...dark.button.subtle,
        background: '#2fb4f1',
        backgroundActive: '#2fb4f1',
        backgroundHover: '#2fb4f1',
      },
    },
    colors: {
      ...dark.colors,
      primary: '#ffffff',
      primaryBright: '#42E8E0',
      primaryDark: '#ffffff',
      // textSubtle: '#b83280',
      secondary: '#1782b3',
      // text: '#b83280',
      background: '#000000',
      card: '#272c2c',
      tertiary: '#1782b3',
      input: '#494e4e',
    },
  }
  return (
    <ThemeContext.Provider value={{ isDark, toggleTheme }}>
      <SCThemeProvider theme={isDark ? darkTheme : lightTheme}>{children}</SCThemeProvider>
    </ThemeContext.Provider>
  )
}

export { ThemeContext, ThemeContextProvider }
